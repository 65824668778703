import React, {memo, useContext} from "react"
import tribeCircle from "public/image/tribeVillageCricle.webp"
import style from "styles/tribe.module.css"
import ThemeContext from '../../../../../../../../context/themeContext'

const Tribe = () => {
    // const [flag, setFlag] = useState(false);
    const { toggleActiveTribe, toggleActiveTribeMobile } = useContext(ThemeContext);
    return (
        <div
            className={style.tribe}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewTribe
                    name="Leaw Beach"
                    img={leawBeach}
                />
            </div> */}
            <div className={style.circle}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveTribeMobile()
                    //@ts-ignore
                    toggleActiveTribe()
                }}>
                <img
                    src={tribeCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}


export default memo(Tribe);

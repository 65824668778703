import React, {memo, useContext} from "react"
import techVilleCircle from "public/image/techVilleCircle.webp"
import style from "styles/techVille.module.css"
import ThemeContext from '../../../../../../../../context/themeContext'

const TechVille = () => {
    // const [flag, setFlag] = useState(false);
    const { toggleActiveTechMobile } = useContext(ThemeContext);
    const { toggleActiveTech } = useContext(ThemeContext);

    // const handleBoxToggleEnter = () => {
    //     setFlag(true)
    // };

    // const handleBoxToggleLeave = () => {
    //     setFlag(false)
    // };
    return (
        <div
            className={style.techVille}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewTech
                    name="Tech Ville"
                    img={techVille}
                />
            </div> */}
            <div className={style.circle}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveTechMobile()
                    //@ts-ignore
                    toggleActiveTech()
                }}>
                <img
                    src={techVilleCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}


export default memo(TechVille);

import { memo } from "react";
import CyberArea from "./components/CyberArea";
import ElectricFactory from "./components/ElectricFactory";
import style from 'styles/circles.module.css'
import MiningAndAirdropArea from "./components/MiningAndAirdropArea";
import SlumVille from './components/SlumVille'
import TechVille from './components/TechVille'
import LeawBeach from './components/LeawBeach'
import TeslaCoil from './components/TeslaCoil'
import Tribe from './components/Tribe'

const Circles = () => {

    return (
        <div className={style.map}>
            <CyberArea />
            <ElectricFactory />
            <MiningAndAirdropArea />
            <SlumVille />
            <TechVille />
            <LeawBeach />
            <TeslaCoil />
            <Tribe />
        </div>
    )
};

export default memo(Circles);

import React, {memo, useContext} from "react"
import cyberAreaCircle from "public/image/cuberCityCircle.webp"
import style from "styles/cyberArea.module.css"
import ThemeContext from '../../../../../../../../context/themeContext'

const CyberArea = () => {


    const { toggleActiveCyberMobile } = useContext(ThemeContext);
    const { toggleActiveCyber } = useContext(ThemeContext);
    // const [flag, setFlag] = useState(false);
    // const handleBoxToggleEnter = () => {
    //     setFlag(true)
    // };

    // const handleBoxToggleLeave = () => {
    //     setFlag(false)
    // };
    return (
        <div
            className={style.cyberArea}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewCyberArea
                    name="Cyber area"
                    img={cyberArea}
                />
            </div> */}
            <div className={style.circle_cyberArea}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveCyberMobile()
                    //@ts-ignore
                    toggleActiveCyber()
                }}
            >
                <img
                    src={cyberAreaCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}


export default memo(CyberArea);

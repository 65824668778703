import React, {memo, useContext} from "react"
import miningCircle from "public/image/miningCircle.webp"
import style from "styles/mining.module.css"
import ThemeContext from '../../../../../../../../context/themeContext'

const Mining = () => {
    // const [flag, setFlag] = useState(false);
    const { toggleActiveMiningMobile } = useContext(ThemeContext);
    const { toggleActiveMining } = useContext(ThemeContext);

    // const handleBoxToggleEnter = () => {
    //     setFlag(true)
    // };

    // const handleBoxToggleLeave = () => {
    //     setFlag(false)
    // };
    return (
        <div
            className={style.mining}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewMining
                    name="Mining and Airdrop area"
                    img={miningAndAirdropArea}
                />
            </div> */}
            <div className={style.circle}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveMiningMobile()
                    //@ts-ignore
                    toggleActiveMining()
                }}>
                <img
                    src={miningCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}


export default memo(Mining);

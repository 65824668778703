import React, {memo, useContext} from "react"
import leawBeachCircle from "public/image/leawBeachCircle.webp"
import style from "styles/leawBeach.module.css"
import ThemeContext from '../../../../../../../../context/themeContext'

const LeawBeach = () => {
    // const [flag, setFlag] = useState(false);
    const { toggleActiveLeawMobile } = useContext(ThemeContext);
    const { toggleActiveLeaw } = useContext(ThemeContext);

    // const handleBoxToggleEnter = () => {
    //     setFlag(true)
    // };

    // const handleBoxToggleLeave = () => {
    //     setFlag(false)
    // };
    return (
        <div
            className={style.leawBeach}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewTribe
                    name="Leaw Beach"
                    img={leawBeach}
                />
            </div> */}
            <div className={style.circle}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveLeawMobile()
                    //@ts-ignore
                    toggleActiveLeaw()
                }}>
                <img
                    src={leawBeachCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}


export default memo(LeawBeach);

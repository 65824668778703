import React, {memo, useContext} from "react"
import electricFactoryCircle from "public/image/electricFactoryCircle.webp"
import style from "styles/electricFactory.module.css";
import ThemeContext from '../../../../../../../../context/themeContext'

const ElectricFactory = ( ) => {

    // const [flag, setFlag] = useState(false);
    const { toggleActiveFactoryMobile } = useContext(ThemeContext);
    const { toggleActiveFactory } = useContext(ThemeContext);

    // const handleBoxToggleEnter = () => {
    //     setFlag(true)
    // };

    // const handleBoxToggleLeave = () => {
    //     setFlag(false)
    // };

    return (
        <div
            className={style.electricFactory}>
            {/* onMouseLeave={handleBoxToggleLeave}> */}
            {/* <div className={flag ? style.ifTrue : style.ifFalse}>
                <PreviewFactory
                    name="Electric Factory"
                    img={electricFactory}
                />
            </div> */}
            <div className={style.circle}
                // onMouseEnter={handleBoxToggleEnter}
                onClick={() => {
                    //@ts-ignore
                    toggleActiveFactoryMobile()
                    //@ts-ignore
                    toggleActiveFactory()
                }}
            >
                <img
                    src={electricFactoryCircle.src}
                    alt=""
                />
            </div>
        </div>
    )
}

export default memo(ElectricFactory);
